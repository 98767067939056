<template>
  <div>
    <header class="header" />
    <AppLayoutNav />
    <slot />
  </div>
</template>

<script>
import AppLayoutNav from '@/layouts/AppLayoutNav'
export default {
  name: "AppLayoutContacts",
  components: {
    AppLayoutNav
  }
}
</script>

<style scoped>
.header {
  height: 5rem;
  background-color: red;
}
</style>